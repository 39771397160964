import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import BlogSinglePost from "../components/Blog/BlogSinglePost";

const Post = ({ data }) => {
   return (
      <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
         <SearchEngineOptimization
            title="What You Need to Know About Tooling & Mold Making"
            description="Learn about tooling and mold making as part of the manufacturing process. Contact Painted Rhino today to bring your prototype to life."
            // openGraphImage={data.openGraphImage.publicURL}
            // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <BlogSinglePost
            title="What You Need To Know About Tooling and Mold Making"
            date="June 30, 2021"
            author="Painted Rhino"
            category=""
            featuredImage={data.featuredImage.childImageSharp.fluid}
            content={
               <>
                  <p>
                     When it comes to manufacturing, you want to ensure that you have the highest-quality products to achieve the best result. Here's
                     what you need to know about tooling and mold making.
                  </p>
                  <h2>What is Injection Molding?</h2>
                  <p>
                     Before you understand tooling, you need to know the basics about injection molding. This process involves injecting molten
                     plastic into a previously designed mold, where it cools and hardens into a specific shape. The injection mold is composed of two
                     parts: the core, which is the internal shape of the molding, and the cavity, which is the external shape. Once the piece is
                     removed from the mold, it can be packaged as the final product or sent on for further finishing.
                  </p>
                  <h2>What is Tooling?</h2>
                  <p>
                     Tooling is a complex process that refers to designing the mold and its components. It's important that this procedure is carried
                     out with extreme precision and care, as your final product will only be as good as your original mold. There are six main steps
                     in the tooling process:
                  </p>
                  <ol>
                     <li>
                        <b>Manufacturability: </b>In the first stage, engineers work together to determine mold materials, resin characteristics and
                        any other product specifications.
                     </li>
                     <li>
                        <b>Design: </b>Next, preliminary models are constructed to determine the size of steel needed and what the mold will look
                        like.
                     </li>
                     <li>
                        <b>Final Design Specifications: </b>The individual in charge of tooling receives the design specifications and makes any final
                        modifications.
                     </li>
                     <li>
                        <b>Primary and Secondary Tool Construction: </b>The tool drawings are completed, and various meetings are held to discuss
                        construction standards and inspect the completed mold.
                     </li>
                     <li>
                        <b>Initial Sample Creation: </b>Once the manufacturing department has decided on the best molding process, initial sampling is
                        carried out.
                     </li>
                     <li>
                        <b>Final Tool Corrections: </b>In the last step of the tooling process, necessary adjustments are made and the parts are
                        submitted to the customer. Once the customer approves, the production process can begin.
                     </li>
                  </ol>
                  <h2>Should You Use Steel or Aluminum?</h2>
                  <p>
                     Although most molds are made from steel, there are also some benefits of using aluminum. Aluminum cools rapidly and is easier to
                     operate on, which increases production rates and therefore makes it an attractive option for tooling and mold making. In
                     addition, it's typically much more affordable than steel. However, because aluminum is softer than steel, it's more susceptible
                     to wear over time and can also be more difficult to weld and maintain. This is why aluminum is typically used for prototypes or
                     simpler parts.
                  </p>
                  <p>
                     On the other hand, steel molds are more durable and have a higher production rate, making them a great choice for complex parts.
                     Hardened steel is the least susceptible to wear as compared to pre-hardened steel, but it's important to keep in mind that harder
                     steel can be more brittle and therefore can crack if impacted.
                  </p>
                  <p>
                     As you can see, tooling and mold making is an intricate process that requires the help of a reliable and experienced
                     professional.{" "}
                  </p>
                  <a href="/tooling-mold-making-metal-work/">At Painted Rhino</a>, we'll work closely with you from start to finish, saving you time
                  and money while also meeting your specific needs. Not only is Painted Rhino experienced in the manufacturing industry, but we'll
                  also take on any project that comes our way and ensure that your vision comes to life.
                  <p>Sources:</p>
                  <ol>
                     <li>
                        <a href="/tooling-mold-making-metal-work/">/tooling-mold-making-metal-work/</a>
                     </li>
                     <li>
                        <a href="https://www.designworldonline.com/mold-design-tooling-for-injection-molding/" target="_blank" rel="noreferrer">
                           https://www.designworldonline.com/mold-design-tooling-for-injection-molding/
                        </a>
                     </li>
                  </ol>
               </>
            }
         />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/FB_Global.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Twitter_Global.jpg" }) {
         publicURL
      }
      featuredImage: file(relativePath: { eq: "blog/what-is-tooling-mold-making.jpeg" }) {
         childImageSharp {
            fluid(maxWidth: 2500) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
   }
`;

export default Post;
